import React from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
import Home from '../components/Home/Home';
import About from '../components/Pages/About/About';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Privacy from '../components/Pages/Privacy';
import Protection from '../components/Pages/PortfolioDetails/Protection';
import Residential from '../components/Pages/PortfolioDetails/Residential';
import Corporate from '../components/Pages/PortfolioDetails/Corporate';
import Event from '../components/Pages/PortfolioDetails/Event';
import Travel from '../components/Pages/PortfolioDetails/Travel';
import Asset from '../components/Pages/PortfolioDetails/Asset';
import Cyber from '../components/Pages/PortfolioDetails/Cyber';
import Risk from '../components/Pages/PortfolioDetails/Risk';
import Blog from '../components/Blog/Blog.js';
import BlogDetails from '../components/BlogDetails/BlogDetails.js';

const AppNavigation = () => {
  return (
    <ContextProvider>
      <AnimateMouse/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/protection" element={<Protection />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/event" element={<Event />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="/asset" element={<Asset />} />
        <Route path="/cyber" element={<Cyber />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/risk" element={<Risk />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
      </Routes>
      <BackToTop/>
    </ContextProvider>
  );
};

export default AppNavigation;