import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About us</Link>
      </li>
      <li className="has-dropdown">
        <Link to="">Service</Link>
        <ul className="submenu">
          <li>
            <Link to="/protection">Close Protection</Link>
          </li>
          <li>
            <Link to="/residential">Residential</Link>
          </li>
          <li>
            <Link to="/corporate">Corporate</Link>
          </li>
          <li>
            <Link to="/event">Event Security</Link>
          </li>
          <li>
            <Link to="/risk">Risk Management</Link>
          </li>
          <li>
            <Link to="/asset">Asset Protection</Link>
          </li>
          <li>
            <Link to="/travel">Travel Security</Link>
          </li>
          <li>
            <Link to="/cyber">Cyber Security</Link>
          </li>
        </ul>
        <li>
        <Link to="/blog">Articles</Link>
      </li>
        <li>
        <Link to="/contact">Contact</Link>
      </li>
      </li>
    </ul>
  );
};

export default Menus;
