import React, { useRef,useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import BlogSidebar from "./BlogSidebar";

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: false,
  arrows: false,
};

const BlogPost = () => {
  const sliderRef = useRef(null);
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-40">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/blog-details">
                      <img src="/assets/img/blog/blog-9.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <Link to="#">
                          <i className="fal fa-user"></i>N Tomlinson
                        </Link>
                      </span>
                      <span>
                        <i className="fal fa-calendar-day"></i>AUG 5, 2024
                      </span>
                      <span>
                        <Link to="#">
                          <i className="fal fa-comment"></i>23 COMMENT
                        </Link>
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/blog-details">
                      Losing sight of your role as CPO team member
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      In the case of executive protection, it’s not a good idea for either the client or you to create a bound of personal friendship. Lines will become blurred and when it comes to professional decisions being made it can complicate issue from both sides. […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/blog-details">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
                <article className="postbox__item format-video mb-50 transition-3">
                  <div className="postbox__thumb w-img p-relative">
                    <Link to="/blog-details">
                      <img src="/assets/img/blog/blog-11.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <Link to="#">
                          <i className="fal fa-user"></i>M Obrien
                        </Link>
                      </span>
                      <span>
                        <i className="fal fa-calendar-day"></i>AUG 10, 2024
                      </span>
                      <span>
                        <Link to="#">
                          <i className="fal fa-comment"></i>6 COMMENT
                        </Link>
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/blog-details">
                      The Evolution of Close Protection: AI Meets Human Intuition
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      The world of 𝒄𝒍𝒐𝒔𝒆 𝒑𝒓𝒐𝒕𝒆𝒄𝒕𝒊𝒐𝒏 is evolving at a breakneck pace, and the role of 𝑨𝑰 is becoming indispensable. While human intuition remains irreplaceable, AI-powered tools such as 𝒑𝒓𝒆𝒅𝒊𝒄𝒕𝒊𝒗𝒆 𝒂𝒏𝒂𝒍𝒚𝒕𝒊𝒄𝒔 𝒂𝒏𝒅 𝒃𝒆𝒉𝒂𝒗𝒊𝒐𝒖𝒓𝒂𝒍 𝒃𝒊𝒐𝒎𝒆𝒕𝒓𝒊𝒄𝒔 are elevating security strategies. […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/blog-details">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>

                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb postbox__slider swiper-container w-img p-relative">
                    <Slider ref={sliderRef} {...settings}>
                      <div className="postbox__slider-item">
                        <img src="/assets/img/blog/blog-slider-1.jpg" alt="" />
                      </div>
                      <div className="postbox__slider-item">
                        <img src="/assets/img/blog/blog-slider-1.jpg" alt="" />
                      </div>
                      <div className="postbox__slider-item">
                        <img src="/assets/img/blog/blog-slider-1.jpg" alt="" />
                      </div>
                    </Slider>
                    <div className="postbox-nav">
                      <button
                        onClick={() => sliderRef.current?.slickNext()}
                        className="postbox-slider-button-next"
                      >
                        <i className="fal fa-angle-right"></i>
                      </button>
                      <button
                        onClick={() => sliderRef.current?.slickPrev()}
                        className="postbox-slider-button-prev"
                      >
                        <i className="fal fa-angle-left"></i>
                      </button>
                    </div>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <Link to="#">
                          <i className="fal fa-user"></i>N Tomlinson
                        </Link>
                      </span>
                      <span>
                        <i className="fal fa-calendar-day"></i>SEP 11, 2024
                      </span>
                      <span>
                        <Link to="#">
                          <i className="fal fa-comment"></i>3 COMMENT
                        </Link>
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/blog-details">
                      Intelligence Gathering is a Delicate Balance of Information and Insight
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>

Intelligence gathering is the cornerstone of informed decision-making, whether in government, business, or personal security. It’s a multifaceted process that blends human insight, technology, and strategy, aiming to collect, analyze, and interpret data to anticipate potential outcomes or threats. […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/blog-details">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>
                <div className="basic-pagination">
                  <nav>
                    <ul>
                      <li>
                        <Link to="/blog">
                          <i className="far fa-long-arrow-left"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog">1</Link>
                      </li>
                      <li>
                        <span className="current">2</span>
                      </li>
                      <li>
                        <Link to="/blog">3</Link>
                      </li>
                      <li>
                        <Link to="/blog">4</Link>
                      </li>
                      <li>
                        <Link to="/blog">
                          <i className="far fa-long-arrow-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
