import React from "react";
import Footer from "../../components/common/Footer/Footer";
import Menu from "../../components/common/Menu/Menu";
import BlogDetailsPost from "./BlogDetailsPost";

const BlogDetails = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <BlogDetailsPost />
      <Footer />
    </>
  );
};

export default BlogDetails;
