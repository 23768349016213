import React from "react";
import { Link } from "react-router-dom";
import BlogSidebar from "../Blog/BlogSidebar";

const BlogDetailsPost = () => {
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-20">
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb m-img">
                    <Link to="/blog-details">
                      <img src="assets/img/blog/single-blog.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <Link to="/blog-details">
                          <i className="fal fa-user"></i> N. Tomlinson
                        </Link>
                      </span>
                      <span>
                        <i className="fal fa-calendar-day"></i> July 21, 2024{" "}
                      </span>
                      <span>
                        <Link to="/blog-details">
                          <i className="fal fa-comment"></i> 02 Comments
                        </Link>
                      </span>
                    </div>
                    <h3 className="postbox__title mb-25">
                    Losing sight of your role as CPO team member. 
                    </h3>
                    <div className="postbox__text">
                      <p>
                      In the case of executive protection, it’s not a good idea for either the client or you to create a bound of personal friendship. Lines will become blurred and when it comes to professional decisions being made it can complicate issue from both sides. 

Being friendly is not the same as being friends. Maintaining a positive and polite manner and trying to establish a personal relationship with a client is something else. You are providing a service that is rewarded by pay, you are not a member of the family. 








                      </p>
                      <p>
                      Even though our clients can at times make friendly gestures/comments and conversations to the security team, it’s important to maintain a professional distance. 
Don’t confuse politeness for a window of opportunity to bolster your position.

Intelligent and Successful people see this all the time, and they’re seldom enamored with people trying play the game, They’d rather be away from people like that and you’ll find these people do not last long in the industry. 
        

                      </p>
                      <p>
                      There are also rules to not respecting others who work around the client also
Some people need to feel more important than others and end up making enemy’s and becoming disliked. 
They become the respective DS watcher and only act the part when they know the client is observing them and when not the mask slips. 

Its when the CPO thinks that their job is more important than the work of the client’s Personal Assistant, House manager, nanny, chef, house cleaner and or other staff. 

While the CPO maybe good with the client, they are not necessarily so with everyone else – and treats them differently, with varying levels of respect.


                      </p>

                      <h3 className="postbox__subtite mb-25">
                        Indispensable
                      </h3>
                      <p>
                      Its when the CPO thinks that their job is more important than the work of the client’s Personal Assistant, House manager, nanny, chef, house cleaner and or other staff. 

While the CPO maybe good with the client, they are not necessarily so with everyone else – and treats them differently, with varying levels of respect.


                        <br />
                        <br />
                        Never think you are indispensable… Anyone can be replaced.!!
                        Always be respectful of others who have roles in the client’s life. 
                        Having a big ego does not make you many allies, if the client see you as a strong leader this role and it’s realitve power does not give you license to treat other team members poorly and for you to feel secure in your position. 
Scenarios have happened time and time again . A capable CPO rises up to assume some management responsibility, they become very aware of their role and perceived power and use it to their own advantage without worrying about how that impacts other members of the clients team. 
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <p>
                            <img
                              src="assets/img/blog/single-blog-2.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                        <div className="col-sm-6">
                          <p>
                            <img
                              src="assets/img/blog/single-blog-3.jpg"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                      <h3 className="postbox__subtite mb-25">
                        Cracks 
                      </h3>
                      <p>
                      The client can be blissfully unaware and likes them until they are made aware of cracks in the character of the said CPO 
                      Before long the CPO is looking for work and everyone they treated disrespectfully remembers them for only that.
                      </p>
                    </div>
                    <div className="single-blog-cloud pt-45 pb-45 tp-brand-border">
                      <div className="row align-items-center">
                        <div className="col-12">
                          <div className="postbox__tag tagcloud">
                            <span>Tags:</span>
                            <Link to="/blog-details">Cyber</Link>
                            <Link to="/blog-details">Travel</Link>
                            <Link to="/blog-details">Gadjet</Link>
                            <Link to="/blog-details">Data</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <div className="postbox__comment mb-65">
                  <h3 className="postbox__comment-title">(02) Comment</h3>
                  <ul>
                    <li>
                      <div className="postbox__comment-box mr-80 d-flex">
                        <div className="postbox__comment-info d-flex">
                          <div className="postbox__comment-avater mr-20">
                            <img
                              src="assets/img/blog/comments/comment-1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="postbox__comment-text ml-10 p-relative">
                          <div className="postbox__comment-name">
                            <h5>Phil</h5>
                            <span className="post-meta"> MARCH 10, 2024</span>
                            <div className="postbox__comment-reply">
                              <Link to="/blog-details">
                                <i className="fal fa-share-all"></i>
                              </Link>
                            </div>
                          </div>
                          <p>
                            Well said
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="children">
                      <div className="postbox__comment-box mr-80 d-flex">
                        <div className="postbox__comment-info d-flex">
                          <div className="postbox__comment-avater mr-20">
                            <img
                              src="assets/img/blog/comments/comment-2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="postbox__comment-text ml-10 p-relative">
                          <div className="postbox__comment-name">
                            <h5>Jim Randle</h5>
                            <span className="post-meta"> JUL 10, 2024</span>
                            <div className="postbox__comment-reply">
                              <Link to="/blog-details">
                                <i className="fal fa-share-all"></i>
                              </Link>
                            </div>
                          </div>
                          <p>
                            Good read
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="postbox__comment-box mr-80 d-flex">
                        <div className="postbox__comment-info d-flex">
                          <div className="postbox__comment-avater mr-20">
                            <img
                              src="assets/img/blog/comments/comment-3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="postbox__comment-text ml-10 ">
                          <div className="postbox__comment-name p-relative">
                            <h5>Kristin Watson</h5>
                            <span className="post-meta"> MAY 10, 2024</span>
                            <div className="postbox__comment-reply">
                              <Link to="/blog-details">
                                <i className="fal fa-share-all"></i>
                              </Link>
                            </div>
                          </div>
                          <p>
                            Nice
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="postbox__comment-form">
                  <h3 className="postbox__comment-form-title">
                    Write a comment
                  </h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="postbox__comment-input">
                          <input type="text" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6">
                        <div className="postbox__comment-input">
                          <input type="email" placeholder="Your Email" />
                        </div>
                      </div>
                      <div className="col-xxl-12">
                        <div className="postbox__comment-input">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-xxl-12">
                        <div className="postbox__comment-input">
                          <textarea placeholder="Enter your comment ..."></textarea>
                        </div>
                      </div>
                      <div className="col-xxl-12">
                        <div className="postbox__comment-btn">
                          <button type="submit" className="tp-btn-border">
                            Post Comment
                            <span>
                              <svg
                                width="22"
                                height="8"
                                viewBox="0 0 22 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <svg
                                width="22"
                                height="8"
                                viewBox="0 0 22 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <BlogSidebar/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPost;
