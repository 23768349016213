import React from "react";

const BlogBanner = () => {
  return (
    <>
      <section
        className=""
      >
        <div className="container">
            <div className="col-xxl-12">
              <div className="text-center p-relative z-index-1">
                <h1 className="">PROTEUS SECURITY ARTICLES</h1>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default BlogBanner;
