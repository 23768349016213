import React from "react";
import { Link } from "react-router-dom";

const BlogSidebar = () => {
  return (
    <div className="sidebar__wrapper">
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">Search Here</h3>
        <div className="sidebar__widget-content">
          <div className="sidebar__search">
            <form onSubmit={e => e.preventDefault()}>
              <div className="sidebar__search-input-2">
                <input type="text" placeholder="Search" />
                <button type="submit">
                  <i className="far fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">Categories</h3>
        <div className="sidebar__widget-content">
          <ul>
            <li>
              <Link to="/blog">Close Protection</Link> <span>03</span>
            </li>
            <li>
              <Link to="/blog">Compliance</Link> <span>07</span>
            </li>
            <li>
              <Link to="/blog">Technology</Link> <span>09</span>
            </li>
            <li>
              <Link to="/blog">Personal Protection<span>01</span>
              </Link>
            </li>
            <li>
              <Link to="/blog">Future Security</Link> <span>00</span>
            </li>
            <li>
              <Link to="/blog">Education</Link> <span>26</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">pages</h3>
        <div className="sidebar__widget-content wd-hide-border">
          <ul>
          <li>
              <Link to="/blog">VIP</Link> <span>03</span>
            </li>
            <li>
              <Link to="/blog">Cyber Security</Link> <span>07</span>
            </li>
            <li>
              <Link to="/blog">Business</Link> <span>09</span>
            </li>
            <li>
              <Link to="/blog">Protective Wear<span>01</span>
              </Link>
            </li>
            <li>
              <Link to="/blog">Gadjets</Link> <span>00</span>
            </li>
            <li>
              <Link to="/blog">Travel</Link> <span>26</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">Recent Post</h3>
        <div className="sidebar__widget-content">
          <div className="sidebar__post rc__post">
            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/blog-details">
                  <img src="assets/img/blog/sidebar/blog-sm-1.jpg" alt="" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>4 March. 2024</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/blog-details">
                    Don’t Underestimate Your Personal Safety
                  </Link>
                </h3>
              </div>
            </div>
            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/blog-details">
                  <img src="assets/img/blog/sidebar/blog-sm-2.jpg" alt="" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>1 Jul. 2024</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/blog-details">
                    Designing Human-Machine Interfaces..
                  </Link>
                </h3>
              </div>
            </div>
            <div className="rc__post mb-20 d-flex align-items-center">
              <div className="rc__post-thumb mr-20">
                <Link to="/blog-details">
                  <img src="assets/img/blog/sidebar/blog-sm-3.jpg" alt="" />
                </Link>
              </div>
              <div className="rc__post-content">
                <div className="rc__meta">
                  <span>30 May. 2024</span>
                </div>
                <h3 className="rc__post-title">
                  <Link to="/blog-details">
                    Cyber Security Monthly News
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar__widget mb-40">
        <h3 className="sidebar__widget-title">Popular Tag</h3>
        <div className="sidebar__widget-content">
          <div className="tagcloud">
            <Link to="/blog">security</Link>
            <Link to="/blog">personal</Link>
            <Link to="/blog">business</Link>
            <Link to="/blog">gadjets</Link>
            <Link to="/blog">drone</Link>
            <Link to="/blog">car</Link>
            <Link to="/blog">customised</Link>
            <Link to="/blog">technology</Link>
            <Link to="/blog">hardware</Link>
            <Link to="/blog">software</Link>
            <Link to="/blog">reports</Link>
            <Link to="/blog">news</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;
